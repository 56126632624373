import React from 'react';
import { OverlayCard, Lang } from '@subtropico/shared/types'
import { Text } from '../../atoms'

interface HeroImageProp {
  imageUrl: string
  partStyle: 'hero-tall'
  overlayCard: OverlayCard | null
  lang: Lang
}

const HeroSection: React.FC<HeroImageProp> = ({ 
  imageUrl = 'https://source.unsplash.com/random', 
  partStyle, 
  overlayCard,
  lang = 'en',
  ...rest 
}) => {

  const getOverlayColor = () => {
    switch (overlayCard?.cardStyle) {
      case 'wide-card-hero-overlay-lime':
        return 'bg-yellow-green text-white'
      case 'wide-card-hero-overlay-avo':
        return 'bg-hunter-green text-white'
      case 'wide-card-hero-overlay-blue':
        return 'bg-deep-sky-blue text-white'
      case 'wide-card-hero-overlay-orange':
        return 'bg-carrot-orange text-white'
      case 'wide-card-hero-overlay-gray':
        return 'bg-payne-grey text-white'
    }
  }

return (
  <div className="pb-6">
    <section className={`w-full ${partStyle !== 'hero-tall' ? 'h-mid': 'lg:h-large h-mid'} 
    bg-center bg-cover bg-no-repeat relative flex justify-around ${!!overlayCard ? 'mb-12': ''}`} 
    style={{ 'backgroundImage': `url(${imageUrl})` }} {...rest}>
        {!!overlayCard ? <div className={`mx-auto md:h-24 py-3 md:py-0
        ${getOverlayColor()} text-black absolute md:-bottom-12 -bottom-7 max-w-screen-xxl xl:w-9/12 w-11/12 flex justify-center items-center`}>
        <Text type="P" className="text-center md:text-4xl text-xl">{overlayCard?.cardHeading?.[lang]}</Text>
        </div>: ''}
    </section>
  </div>
)
}

export default HeroSection;
