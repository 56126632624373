import { useToastFuncs } from "@subtropico/shared/src/contexts/ToastContext";
import useFormSubmit from "@subtropico/shared/src/hooks/useFormSubmit";
import React, { useEffect, useState } from "react";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Maxwidth, Input, Textarea, Text, Link } from "../../atoms";

const defaultValues = {
    name: "",
    email: "",
    phoneNumber: "",
    title: "",
    message: "",
    tel: "",
    company_name: "",
};

const ContactForm: React.FC = () => {
    const { executeRecaptcha } = useGoogleReCaptcha()
    const { handleSubmit, error, response, loading } = useFormSubmit();
    const Toast = useToastFuncs();
    const [formValues, setFormValues] = useState(defaultValues);
    const [showForm, setShowForm] = useState(true);

    const handleChange = ({
        target: { name, value },
    }: {
        target: { name: string; value: string };
    }) => {
        setFormValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (error) {
            // @ts-ignore
            Toast?.error?.("Operation failed. Try again");
        } else {
            if (response && !error) {
                // @ts-ignore
                // Toast?.success?.("Operation successful");
                setShowForm(false);
                setFormValues(defaultValues);
            }
        }
    }, [error, response]);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!executeRecaptcha) {
            return
        }
        const token = await executeRecaptcha('limitedContactUs')
        handleSubmit(formValues, "contact-form-limited", token);
    };

    return (
        <Maxwidth>
            <section className="grid lg:grid-cols-2 gap-y-5 gap-x-24">
                {showForm ? (
                    <form onSubmit={onSubmit}>
                        <Input
                            value={formValues?.name}
                            label="Enter your name"
                            isRequired
                            name="name"
                            handleChange={handleChange}
                        />
                        <Input
                            handleChange={handleChange}
                            value={formValues?.title}
                            label="Enter your title"
                            name="title"
                        />
                        <Input
                            handleChange={handleChange}
                            value={formValues?.company_name}
                            label="Enter your company name"
                            name="company_name"
                        />
                        <Input
                            handleChange={handleChange}
                            value={formValues?.email}
                            type="email"
                            label="Enter your email address"
                            name="email"
                            isRequired
                        />
                        <Input
                            handleChange={handleChange}
                            value={formValues?.tel}
                            type="tel"
                            label="Enter your contact number"
                            name="tel"
                            isRequired
                        />
                        <Textarea
                            handleChange={handleChange}
                            name="message"
                            value={formValues?.message}
                            label="Your message"
                            isRequired
                        />
                        <div className="text-center">
                            <button
                                type="submit"
                                className="text-center bg-carrot-orange py-1 px-12 text-white"
                            >
                                {loading ? "Submitting..." : "Submit"}
                            </button>
                        </div>
                    </form>
                ) : (
                    <div className="my-16">
                        <Text type="P" className="bg-orange-red text-white p-5">
                            Message Sent
                        </Text>
                    </div>
                )}
                <aside className="lg:mt-16">
                    <div className="mb-8">
                        <Text type="P" className="text-carrot-orange font-semibold mb-1">
                            Subtropico Limited
                        </Text>
                        <div className="text-dove-grey md:w-4/12 lg:w-10/12 xl:w-4/12 grid grid-cols-5">
                            <Text type="P">Tel:</Text>
                            <Link to="tel:+270124609910" type="external" className="col-span-4">
                                +27 012 460 9910/3/6
                            </Link>
                            <Text type="P"></Text>
                            <Link to="tel:+270124606968" type="external" className="col-span-4">
                                +27 012 460 6968
                            </Link>
                        </div>
                    </div>
                    <div className="mb-8">
                        <Text type="P" className="text-carrot-orange font-semibold mb-1">
                            Email:
                        </Text>
                        <div className="text-dove-grey">
                            <Text type="P">
                                <Link to="mailto:info@subtropico.co.za" type="external">
                                    info@subtropico.co.za
                                </Link>
                            </Text>
                        </div>
                    </div>
                    <div className="mb-8">
                        <Text type="P" className="font-semibold mb-1 text-carrot-orange">
                            Physical address:
                        </Text>
                        <div className="text-dove-grey">
                            <Text type="P">
                                <Link to="https://goo.gl/maps/X1MKNAWrbcM66ery6" type="external">
                                    No. 3 Parkland Buildings, <br />
                                    229 Bronkhorst Street, <br />
                                    Nieuw Muckleneuk, Pretoria
                                </Link>
                            </Text>
                        </div>
                    </div>

                    <div className="mb-8">
                        <Text type="P" className="font-semibold mb-1 text-dove-grey">
                            Whistleblowing / <br />
                            Ethics Tip-off Hotline
                        </Text>
                        <div className="text-dove-grey md:w-4/12 lg:w-10/12 xl:w-6/12 grid grid-cols-4">
                            <Text type="P">Tel:</Text>
                            <Link to="tel:+270124609910" type="external" className="col-span-3">
                                +27 11 488 1883
                            </Link>
                            <Text type="P">Email:</Text>
                            <Link
                                to="mailto:hotline@statucor.co.za"
                                type="external"
                                className="col-span-3"
                            >
                                hotline@statucor.co.za
                            </Link>
                        </div>
                    </div>
                </aside>
            </section>
        </Maxwidth>
    );
};

export default ContactForm;
