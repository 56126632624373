import React from 'react';

const CancelToastIcon = ({ onClick }: { onClick: () => void}) => (
  <div onClick={onClick} className="cancel-toast-icon">
    <svg width="10" height="10" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.5014 2.99808L5.22979 1.2697C5.36782 1.1318 5.36782 0.90816 5.22979 0.770281C5.0919 0.632253 4.8684 0.632253 4.73037 0.770281L3.00196 2.49867L1.27342 0.770269C1.13567 0.632241 0.911882 0.632241 0.774002 0.770269C0.63611 0.90816 0.63611 1.1318 0.774002 1.26968L2.50255 2.99808L0.770178 4.73045C0.632149 4.86847 0.632149 5.09198 0.770178 5.22986C0.839118 5.29894 0.929539 5.33341 1.01996 5.33341C1.11023 5.33341 1.20066 5.29894 1.26961 5.22986L3.00196 3.49751L4.73037 5.22591C4.79947 5.29485 4.88973 5.32946 4.98016 5.32946C5.07043 5.32946 5.16085 5.29485 5.2298 5.22591C5.36783 5.08802 5.36783 4.86452 5.2298 4.72649L3.5014 2.99808Z" fill="white" />
    </svg>
  </div>
);

export default CancelToastIcon;
