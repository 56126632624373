import React, { useEffect, useState } from "react";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Input, Text } from "../../atoms";
import Checkbox from "../../atoms/Checkbox";
import PdfDownloadLink from "./PdfDownloadLink";
import useFormSubmit from "../../../../../../shared/src/hooks/useFormSubmit";
import { useToastFuncs } from "@subtropico/shared/src/contexts/ToastContext";
const defaultValues = {
    name: "",
    idNo: "",
    address: "",
    city: "",
    postalCode: "",
    email: "",
    phoneNumber: "",
    noOfShares: "",
    purchasePrice: "",
    disclosePermission: false,
    userDataConfirm: false,
};

const PurchaseForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha()
    const { handleSubmit, error, response, loading } = useFormSubmit();
    const Toast = useToastFuncs();
    const [formValues, setFormValues] = useState(defaultValues);

    const handleChange = ({
        target: { name, value },
    }: {
        target: { name: string; value: string };
    }) => {
        setFormValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (error) {
            // @ts-ignore
            Toast?.error("Operation failed. Try again");
        } else {
            if (response && !error) {
                // @ts-ignore
                Toast?.success("Operation successful");
                setFormValues(defaultValues);
            }
        }
    }, [error, response]);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!executeRecaptcha) {
            return
        }
        const token = await executeRecaptcha('limitedPurchaseForm')
        handleSubmit(formValues, "purchase-form", token);
    };

    return (
        <div>
            <form onSubmit={onSubmit}>
                <Input
                    label="Enter your name"
                    value={formValues.name}
                    handleChange={handleChange}
                    name="name"
                    isRequired
                    disabled={loading}
                />
                <Input
                    label="Enter your ID No/ Registration No"
                    value={formValues.idNo}
                    handleChange={handleChange}
                    name="idNo"
                    isRequired
                    disabled={loading}
                />
                <Input
                    label="Address"
                    value={formValues.address}
                    handleChange={handleChange}
                    name="address"
                    isRequired
                    disabled={loading}
                />
                <Input
                    label="City"
                    value={formValues.city}
                    handleChange={handleChange}
                    name="city"
                    isRequired
                    disabled={loading}
                />
                <Input
                    label="Postal Code"
                    value={formValues.postalCode}
                    handleChange={handleChange}
                    name="postalCode"
                    isRequired
                    disabled={loading}
                />
                <Input
                    type="email"
                    label="Email"
                    value={formValues.email}
                    handleChange={handleChange}
                    name="email"
                    isRequired
                    disabled={loading}
                />
                <Input
                    type="tel"
                    label="Phone number"
                    value={formValues.phoneNumber}
                    handleChange={handleChange}
                    name="phoneNumber"
                    isRequired
                    disabled={loading}
                />
                <div className="mt-8">
                    <Text type="P" className="text-bold text-dove-grey">
                        Number of shares offered for sale
                    </Text>
                </div>
                <div className="flex my-4 items-end">
                    <div className="flex-grow">
                        <Input
                            type="number"
                            label="Enter number of shares"
                            value={formValues?.noOfShares}
                            handleChange={handleChange}
                            name="noOfShares"
                            isRequired
                            disabled={loading}
                        />
                    </div>
                    <div className="mb-11 ml-4">
                        <Text type="P" className="text-dove-grey">
                            Ordinary shares
                        </Text>
                    </div>
                </div>

                <Text type="P" className="text-dove-grey">
                    The tender particulars entered above is merely an indication of intent to
                    commence bilateral negotiation and not a formal commitment.
                </Text>

                <div className="flex my-4 items-end">
                    <div className="flex-grow">
                        <Input
                            type="number"
                            label="R"
                            value={formValues.purchasePrice}
                            handleChange={handleChange}
                            name="purchasePrice"
                            isRequired
                            disabled={loading}
                        />
                    </div>
                    <div className="mb-12 ml-4">
                        <Text type="P" className="text-dove-grey">
                            per share
                        </Text>
                    </div>
                </div>
                <div className="my-8">
                    <Checkbox
                        name="disclosePermission"
                        value=""
                        handleChange={() => {
                            setFormValues((prev) => ({
                                ...prev,
                                disclosePermission: !prev?.disclosePermission,
                            }));
                        }}
                        type="checkbox"
                        defaultChecked={formValues?.disclosePermission}
                        label="I hereby give permission that my details may be disclosed to potential sellers."
                    />
                    <Checkbox
                        name="userDataConfirm"
                        value=""
                        handleChange={() => {
                            setFormValues((prev) => ({
                                ...prev,
                                userDataConfirm: !prev?.userDataConfirm,
                            }));
                        }}
                        type="checkbox"
                        defaultChecked={formValues?.userDataConfirm}
                        label="All info I have submitted is 100% correct to my best knowledge"
                    />
                </div>

                <div className="text-center">
                    <button
                        type="submit"
                        disabled={
                            loading ||
                            !formValues?.userDataConfirm ||
                            !formValues?.disclosePermission
                        }
                        className="text-center bg-carrot-orange py-1 px-12 h-12 text-white w-full max-w-xs"
                    >
                        {loading ? "Submitting..." : "Submit form"}
                    </button>
                </div>
                <Text className="my-8 text-dove-grey" type="P">
                    Alternatively you can download the Purchase Form here:{" "}
                </Text>
                <PdfDownloadLink
                    url="https://cdn.sanity.io/files/vvg53w03/limited/f32e1c1d94aad3e619d30baf99e5968d38a339a2.pdf"
                    text="Download the purchase form here"
                />
            </form>
        </div>
    );
};

export default PurchaseForm;
