import React from 'react'
import { Text, Link, Maxwidth } from '../../atoms'
import { Button } from '@subtropico/shared/types';
import { getButtonLink, getUrl } from '@subtropico/shared/utils';
import { LimitedLogo } from '@subtropico/shared';
import { useGlobalStateContext } from '../../context/app'

interface IContactCard {
  title?: string,
  button?: Button
  imageUrl?: string
}

const ContactCard: React.FC<IContactCard> = ({ title, button, imageUrl }) => {
  const { pageContextState } = useGlobalStateContext()

  return (
    <section className="px-10 my-16 bg-center bg-cover bg-no-repeat" style={{ backgroundImage: `url(${imageUrl})` }}>
      <Maxwidth>
        <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 justify-between items-center py-5 lg:py-0">
          <div><LimitedLogo /></div>
          <Text type="P" className="text-white text-3xl font-normal">{title}</Text>
          <Link to={getUrl(pageContextState?.locale!, getButtonLink(button?.buttonLink, pageContextState?.locale!)) || '/contact'}
            className="py-2 px-10 bg-carrot-orange border border-carrot-orange text-white transition-colors duration-1000 hover:bg-transparent hover:text-carrot-orange">
            {button?.buttonText?.[pageContextState?.locale!]}</Link>
        </div>
      </Maxwidth>
    </section>
  )
}

export default ContactCard
