import React from 'react'
import { Text } from '../../atoms'
import PdfFile from '../../atoms/svg/PdfFile'

interface Props {
  url: string;
  text: string;
}

const PdfDownloadLink = (props: Props) => {
  return (
      <a style={{width: 'fit-content'}} target="_blank" href={props?.url} className="flex items-center mx-auto justify-center mb-8">
        <div className="mr-4">
         <PdfFile />
        </div>
      <Text type='P' className='text-carrot-orange underline' >{props?.text}</Text>
      </a>
  )
}

export default PdfDownloadLink
