import React from  'react';
import { CardCollection as CardCollectionType  } from '../../../types'
import { ArticleCard, PortableTextRender, SimpleCard } from '../../molecules'
import { Maxwidth, Image, Text } from '../../atoms'
import { Slider } from '@subtropico/shared'
import { useGlobalStateContext } from '../../context/app'
import InfoCard from '../Cards/InfoCard';
import { sanityImageURL } from '../../../utils'

const CardCollection: React.FC<CardCollectionType> = ({ cardCollectionStyle, cards, intro, heading }) => {

  const { pageContextState } = useGlobalStateContext();

  switch (cardCollectionStyle) {
    case 'simple-investor-4th-card-image-card-collection':
      return <Maxwidth>
        <div className="grid md:grid-cols-2 gap-10 py-16">
          {cards?.map((card, index) => (
            <InfoCard lang={pageContextState?.locale!} key={index} image={card?.image} titleColour={card?.titleColour} heading={card?.heading} body={card?.body} button={card?.button} />
          ))}
        </div>
      </Maxwidth>

    case 'read-more-large-card-collection':
      return <div className="shadow-upperDirection mb-8 mt-16 py-10">
        <Maxwidth>
          <div className="my-5 text-center">
            {intro? <PortableTextRender body={intro} />: ''}
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-10 my-8">
            {cards?.map((card, index) => (
              <div className="h-72" key={index}>
                <ArticleCard key={index} link={card?.cardLinks?.[0]} button={card?.button} heading={card?.heading?.[pageContextState?.locale!]} 
                  imageUrl={sanityImageURL(card?.image)!} type='lg' lang={pageContextState?.locale!} />
              </div>
            ))}
          </div>
        </Maxwidth>
      </div>

    case 'read-more-small-card-collection':
      return <div className="my-10">
        <Maxwidth>
          <div className="my-5">
            {intro? <PortableTextRender body={intro} />: ''}
            {heading?.[pageContextState?.locale!] ? <Text type='P'>{heading?.[pageContextState?.locale!]}</Text> : ''}
          </div>
          <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 gap-10 my-10">
            {cards?.map((card, index) => (
              <div className="h-44" key={index}>
                <ArticleCard key={index} link={card?.cardLinks?.[0]} button={card?.button} heading={card?.heading?.[pageContextState?.locale!]} 
                  imageUrl={sanityImageURL(card?.image)!} lang={pageContextState?.locale!} />
              </div>
            ))}
          </div>
        </Maxwidth>
      </div>

    case 'featured-card-collection':
      return <div className="my-10">
        <Maxwidth>
          <div className="my-5">
            {intro? <PortableTextRender body={intro} />: ''}
          </div>
          <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 gap-10 my-10">
            {cards?.map((card, index) => (
              <div className="h-44" key={index}>
                <ArticleCard key={index} link={card?.cardLinks?.[0]} button={card?.button} heading={card?.heading?.[pageContextState?.locale!]} 
                  imageUrl={sanityImageURL(card?.image)!} position="top" lang={pageContextState?.locale!} />
              </div>
            ))}
          </div>
        </Maxwidth>
      </div>


    case 'partner-logo-card-collection':
      return <div className="mt-10 mb-16">
        <Maxwidth>
          <div className="my-6 text-center">
            {intro? <PortableTextRender body={intro} />: ''}
          </div>
          <Slider>
            {cards?.map((card, index) => (
              <Image src={sanityImageURL(card?.image)!} alt={card.title || 'partner'} className="object-contain  h-16" key={index} />
            ))}
          </Slider>
        </Maxwidth>
      </div>

    case 'simple-read-next':
      return <div className="mt-10 mb-16">
        <Maxwidth>
        <div className="my-6 ">
            {intro? <PortableTextRender body={intro} />: ''}
        </div>
        <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 gap-4 my-10">
            {cards?.map((card, index) => (
              <div className="h-28" key={index}>
                <SimpleCard heading={card?.heading?.[pageContextState?.locale!]} link={card?.cardLinks?.[0]} lang={pageContextState?.locale!} />
              </div>
            ))}
          </div>
        </Maxwidth>
      </div>

    default:
      return <div>render style for {cardCollectionStyle} isn't available</div>
  } 
}

export default CardCollection;
