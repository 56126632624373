import React from 'react';
import { CardWide as ICardWide } from '../../../types'
import ContactCard from '../Cards/ContactCard';
import { useGlobalStateContext } from '../../context/app'


const CardWide: React.FC<ICardWide> = ({ cardStyle, image, cardHeading, button }) => {

  const { pageContextState } = useGlobalStateContext()

  switch (cardStyle) {
    case 'wide-card-contact-us':
      return <ContactCard imageUrl={image} title={cardHeading?.[pageContextState?.locale!]} button={button} />
    default:
      return <div>No component available for {cardStyle}</div>
  }
}

export default CardWide;
