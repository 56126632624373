import React from 'react';

const SuccessToastIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5633 0.0765961C9.11206 -0.19847 8.60873 0.332014 8.31369 0.646375C7.63684 1.39299 7.0641 2.25747 6.4219 3.04337C5.71031 3.90787 5.0508 4.77236 4.32186 5.61724C3.90531 6.08879 3.45407 6.59961 3.17637 7.18905C2.55157 6.50135 2.01354 5.75474 1.31931 5.1457C0.815989 4.71345 -0.0170889 4.39909 0.000266917 5.44041C0.0349784 6.79612 1.09368 8.25005 1.87469 9.17345C2.20446 9.5664 2.63834 9.979 3.14166 9.99865C3.74911 10.0379 4.37393 9.21273 4.7384 8.76085C5.38059 7.97495 5.90126 7.09077 6.49133 6.28526C7.25499 5.22429 8.036 4.18294 8.7823 3.10232C9.2509 2.4343 10.7261 0.783876 9.5633 0.0765961ZM0.763893 5.36182C0.746537 5.36182 0.729181 5.36182 0.69447 5.38144C0.625047 5.36182 0.57298 5.34214 0.503556 5.30285C0.555624 5.26355 0.642403 5.2832 0.763893 5.36182Z" fill="white" />
  </svg>

);

export default SuccessToastIcon;
