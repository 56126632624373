import React from 'react';
import HeroSection from '../../common/components/organism/HeroSection'
import { Lang } from '@subtropico/shared/types'
import { PagePart } from '../../common/types';
import { sanityImageURL } from '../../common/utils'
import SimpleContentBlock  from '../../common/components/organism/SimpleContentBlock'
import SimpleContentGrid  from '../../common/components/organism/SimpleContentGrid'
import FinancialTable  from '../../common/components/organism/FinancialTable'
import { IframeRender } from '../../common/components/atoms'
import CardWide  from '../../common/components/organism/CardWide'
import CardCollection  from '../../common/components/organism/CardCollection'
import ContactForm from '../../common/components/organism/ContactForm';
import TwinForm from "../../common/components/organism/TwinForm";

interface IProp {
    part: PagePart;
    id: number;
    lang: Lang;
}

const PagePartRenderer: React.FC<IProp> = ({ part, id, lang }) => {
    switch (part._type) {
        case "hero":
            return (
                <HeroSection
                    lang={lang}
                    imageUrl={sanityImageURL(part?.image)!}
                    partStyle={part?.partStyle || "hero-tall"}
                    overlayCard={part?.overlayCard?.[0] || null}
                />
            );

    case 'simpleContentBlock':
      return <SimpleContentBlock body={part?.body} lang={lang} image={part.image} partStyle={part?.partStyle}
      image_placement={part?.image_placement} navanchor={part.navanchor} button={part?.button} />

    case 'mapRender':
        return <IframeRender fullWidth={true} source={part?.source} width={part?.width} height={part?.height} />

    case 'simpleContentGrid':
      return <SimpleContentGrid body={part?.body} image={part.image} partStyle={part?.partStyle} button={part?.button} />

    case 'cardWide':
      return <CardWide cardStyle={part?.cardStyle} cardHeading={part?.cardHeading} button={part?.button} image={sanityImageURL(part?.image)!} />

    case 'financialTable':
        return <FinancialTable table={part?.table} partStyle={part?.partStyle} />

    case 'cardCollection':
      return <CardCollection cardCollectionStyle={part?.cardCollectionStyle} cards={part?.cards} intro={part?.intro} heading={part?.heading} />

    case 'formPart':
        return <ContactForm />

    case "twinForm":
        return <TwinForm forms={part?.forms} />;

    default:
        return (
            <div key={id} className="border-red-500 border">
                Missing Component Handler for type {part._type}{" "}
            </div>
        );
    }
};

export default PagePartRenderer;
