import React from 'react';
import { FinancialTable as FinancialTableType } from '../../../types';
import { Maxwidth } from '../../atoms'

const FinancialTable: React.FC<FinancialTableType> = ({ table, partStyle }) => {
  const rows = table?.rows;
  switch (partStyle) {
    case  'financial-review-table':
      return (
        <Maxwidth className='lg:w-7/12'>
          <div className=''>
          {rows?.map((item, i) => <div className={`${i === 0 ? 'font-bold col-span-2': ''} 
          ${item?.cells?.length === 8 ? 'grid grid-cols-9' : 'grid grid-cols-4'}  border-dove-grey border-b py-4 text-dove-grey gap-8 items-center`} key={i}>
            {item.cells?.map((cell, j) => <div className={`${j === 0 ? 'text-left col-span-2' : 'text-right'} whitespace-pre-wrap`} key={j}>{cell}</div>)}
            </div>)}
          </div>
        </Maxwidth>
      )
    case 'previous-months-table':
      return (
        <Maxwidth className='lg:w-7/12'>
        <div className=''>
        {rows?.map((item, i) => <div className='grid grid-cols-2 md:grid-cols-4 border-dove-grey border-b py-4 text-dove-grey' key={i}>
          {item.cells?.map((cell, j) => <div className={`${j === 0 ? 'md:col-span-1': 'md:col-span-3' }`} key={j}>{cell}</div>)}
          </div>)}
        </div>
      </Maxwidth>
      )
    default:
      return <></>
  }
};

export default FinancialTable;
