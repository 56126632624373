import React, { useState } from "react";
import { ITwinForm } from "../../../types";
import { Text } from "../../atoms";
import BuyShares from "../../atoms/svg/BuyShares";
import ColoredBuyShares from "../../atoms/svg/ColoredBuyShares";
import ColoredSellShares from "../../atoms/svg/ColoredSellShares";
import SellShares from "../../atoms/svg/SellShares";
import PurchaseForm from "./PurchaseForm";
import SalesForm from "./SalesForm";
import "./style.css";

interface Iform {
    titles: { [key: string]: string };
    icons: { [key: string]: any };
    formTitle: { [key: string]: string };
    formDesc: { [key: string]: string };
    formComponent: { [key: string]: any };
}

const formInfo: Iform = {
    titles: {
        "buy-shares-form": "I want to buy shares",
        "sell-shares-form": "I want to sell shares",
    },
    icons: {
        "sell-shares-form-colored": ColoredSellShares,
        "buy-shares-form-colored": ColoredBuyShares,
        "buy-shares-form": BuyShares,
        "sell-shares-form": SellShares,
    },
    formTitle: {
        "buy-shares-form": "Purchase tender form",
        "sell-shares-form": "Sales Tender form",
    },
    formDesc: {
        "buy-shares-form":
            "I hereby tender to purchase shares in SUBTROPICO LIMITED, representing myself/the legal entity mentioned below:",
        "sell-shares-form":
            "I hereby tender to sell shares in SUBTROPICO LIMITED (Reg No.: 1993/000220/06), representing myself/the legal entity mentioned below.",
    },
    formComponent: {
        "buy-shares-form": PurchaseForm,
        "sell-shares-form": SalesForm,
    },
};

const TwinForm: React.FC<ITwinForm> = ({ forms }) => {
    const [formInView, setFormInview] = useState("buy-shares-form");

    const getIcon = (iconType: any) => {
        const Icon = formInfo.icons[`${iconType}${isInview(iconType) ? "-colored" : ""}`];
        const props = !isInview(iconType) ? {} : { width: "130px", height: "145px" };
        return Icon ? <Icon {...props} /> : <></>;
    };

    const showIcon = (formId: any) => {
        const Icon = formInfo.icons[`${formId}-colored`];
        return <Icon />;
    };

    const isInview = (formId: any) => formId === formInView;
    const Form = formInfo?.formComponent[formInView];
    return (
        <section className="twin-form max-w-screen-xxl mx-auto w-11/12  lg:w-7/12 my-8 shadow-lg">
            <header className="grid grid-cols-2 shadow-lg">
                {forms.map((form, i) => (
                    <div
                        key={i}
                        onClick={() => setFormInview(form?.simpleForm?.formId || "buy-shares-form")}
                        className={`h-64 flex flex-col cursor-pointer ${
                            isInview(form?.simpleForm?.formId) ? "bg-carrot-orange" : ""
                        } p-8`}
                    >
                        <Text
                            type="H4"
                            className={isInview(form?.simpleForm?.formId) ? "text-white" : ""}
                        >
                            {formInfo?.titles[form?.simpleForm?.formId || "buy-sell-shares"]}
                        </Text>
                        <div className="icon flex-grow flex items-center justify-center">
                            {getIcon(form?.simpleForm?.formId || "buy-sell-shares")}
                        </div>
                    </div>
                ))}
            </header>
            <div className="p-8 relative">
                <div className="hidden md:block absolute top-8 left-8 mr-4">
                    {showIcon(formInView)}
                </div>
                <div className="w-11/12 sm:w-17/24 max-w-lg mx-auto">
                    <Text type="H4" className="my-8">
                        {formInfo?.formTitle[formInView]}
                    </Text>
                    <Text type="P" className="my-8 text-dove-grey">
                        {formInfo?.formDesc[formInView]}
                    </Text>
                    <Form />
                </div>
            </div>
        </section>
    );
};

export default TwinForm;
