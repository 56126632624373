import React from "react";
import { PortableTextRender } from '../../molecules';
import { SimpleContentGrid as SimpleContentGridType } from '../../../types'
import { useGlobalStateContext } from '../../context/app'
import { Maxwidth, Link } from '../../atoms'
import { getButtonLink, getUrl } from "@subtropico/shared/utils";


const SimpleContentGrid: React.FC<SimpleContentGridType> = ({ body, button }) => {
  const { pageContextState } = useGlobalStateContext()
  const lang = pageContextState?.locale
  

  return (<>
    <Maxwidth className="lg:w-7/12">
      {pageContextState?.locale && body?.map((item, i) => 
      <PortableTextRender body={item?.[pageContextState?.locale!]} key={i} /> )}

      <div>
        <Link to={getUrl(pageContextState?.locale!, getButtonLink(button?.buttonLink, lang))} 
          className="inline-block bg-carrot-orange border border-carrot-orange px-3 py-1 text-white font-medium hover:bg-transparent hover:text-carrot-orange transition-colors duration-300">
          {button?.buttonText?.[pageContextState?.locale!] ? button?.buttonText?.[pageContextState?.locale!] : ''}
        </Link>
      </div>
    </Maxwidth>
  </>)
}

export default SimpleContentGrid;
