/* eslint-disable react-hooks/exhaustive-deps -- uses N8N */
import React from "react";
import fetchCall from "../../utils/fetchCall";

const useFormSubmit = () => {
    const [response, setResponse] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    const handleSubmit = React.useCallback(async (formData: any, formName,  token: string) => {
        try {
            setLoading(true);
            setError(null);
            setResponse(null);
            const body = {
                token,
                formName,
                data: formData,
            };
            const res = await fetchCall({
                url: "https://n8n.wobot.workshoporange.co/webhook/1m9eb5zc-8av8-4a2c-10y4-77kd4fe02b91",
                method: "POST",
                data: body,
            });
            setLoading(false);
            // if success
            setResponse(res);
        } catch (e: any) {
            setLoading(false);
            setError(e?.response?.data?.message);
        }
    }, []);

    return { handleSubmit, response, loading, error, setResponse };
};

export default useFormSubmit;