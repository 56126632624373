import React from 'react';
import { PortableTextRender } from '../../molecules'
import { Maxwidth, Image, Link } from '../../atoms'
import { Lang, SimpleContentBlockType, ImagePlacement, PartStyle, Button } from '@subtropico/shared/types'
import { sanityImageURL } from '../../../utils'

interface SimpleContentBlockProp {
  body: {[key in Lang]: SimpleContentBlockType[] }[]
  lang: Lang
  image?: any
  image_placement?: ImagePlacement
  navanchor?: string
  partStyle?: PartStyle
  button?: Button
}

const SimpleContentBlock: React.FC<SimpleContentBlockProp> = ({ body, lang="en", image, image_placement, navanchor, partStyle, button }) => {
  const render = () => {
    if(image) {
      const imageUrl = sanityImageURL(image);

      const left = () => {
        return (
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8" id={navanchor || ''}>
            <div>
              <Image src={imageUrl} alt="" className="" />
            </div>
            <div className="col-span-3">
              {body?.map((item, i) => <PortableTextRender body={item?.[lang]} key={i} /> )}
            </div>
          </div>
        )
      }

      switch(partStyle) {
        case 'group-structure-infographic':
          return (
            <div className="gap-8" id={navanchor || ''}>
              <div className='flex justify-center w-full my-8'>
                <Image src={imageUrl} alt="" className="h-[500px] w-[500px]" />
              </div>
              <div className='w-full flex justify-center'>
                <div className="grid sm:grid-cols-2 gap-8 mx-auto">
                  <div>
                    {body?.length && <PortableTextRender body={body?.[0]?.[lang]} />}
                  </div>
                  <div>
                    {body?.length &&  <PortableTextRender body={body?.[1]?.[lang]} /> }
                  </div>
                </div>

              </div>
            </div>
          )
      }

      switch (image_placement) {
        case 'left':
          return left()

        case 'right':
          return (
            <div className="grid md:grid-cols-2 gap-8" id={navanchor || ''}>
              <div>
                {body?.map((item, i) => <PortableTextRender body={item?.[lang]} key={i} /> )}
              </div>
              <div>
                <Image src={imageUrl} alt="" className="h-full" />
              </div>
            </div>
          )
        case 'top-center':
          return (
            <div className="grid place-items-center" id={navanchor || ''}>
              <div className="text-center">
                <Image src={imageUrl} alt="" className="h-full" />
              </div>
              <div>
                {body?.map((item, i) => <PortableTextRender body={item?.[lang]} key={i} /> )}
              </div>
            </div>
          )
        case 'below':
          return (
            <div className="mt-8" id={navanchor || ''}>
              <div className="mt-16">
                {body?.map((item, i) => <PortableTextRender body={item?.[lang]} key={i} />)}
              </div>
              <div>
                {button ? <Link to={button?.buttonHref?.[lang]}><Image src={imageUrl} alt="" className="h-full" /></Link> : <Image src={imageUrl} alt="" className="h-full" />}
              </div>
            </div>
          )

        default:
        return (
          <div className="mt-8" id={navanchor || ''}>
            <div>
              {button ? <Link to={button?.buttonHref?.[lang]}><Image src={imageUrl} alt="" className="h-full" /></Link> : <Image src={imageUrl} alt="" className="h-full" />}
            </div>
            <div className="mt-16">
              {body?.map((item, i) => <PortableTextRender body={item?.[lang]} key={i} /> )}
            </div>
          </div>
        )
      }
    } else {
      return (
        <div id={navanchor || ''}>
          {body?.map((item, i) => <PortableTextRender body={item?.[lang]} key={i} /> )}
        </div>
      )
    }
  }
  return (
    <Maxwidth className="lg:w-7/12">
      {render()}
    </Maxwidth>
)}

export default SimpleContentBlock;
