import React from "react";
import { PortableTextRender } from "../../molecules";
import renderLinkColor from "../../molecules/PortableTextRender";
import { Text, Link } from "../../atoms";
import { Card, Lang } from "@subtropico/shared/types";
import { sanityImageURL } from "../../../utils";
import { useGlobalStateContext } from "../../context/app";
import { getUrl, getButtonLink } from "@subtropico/shared/utils";

const InfoCard: React.FC<Card & { lang: Lang }> = ({ image, heading, body, button, lang, titleColour }) => {
    const { pageContextState } = useGlobalStateContext();
    const imageUrl = sanityImageURL(image);

    return (
        <section
            className="shadow-fullSides-sm flex flex-col justify-between p-4 bg-cover bg-no-repeat bg-white bg-top"
            style={{
                backgroundImage: `url(${imageUrl})`,
                backgroundPositionX: "right",
                backgroundPositionY: "center",
                minHeight: "280px",
            }}
        >
            <div>
                <Text type="H6" className={`${(titleColour || titleColour === 'white') ? 'text-white': 'text-payne-grey'} mb-3 text-lg font-semibold`}>
                    {heading?.[pageContextState?.locale!]}
                </Text>
                <div className="text-dove-grey">
                    {
                        <div>
                            {pageContextState?.locale
                                ? body?.map((item, i) => {
                                      return item?.[pageContextState?.locale!] ? (
                                          <PortableTextRender
                                              body={item?.[pageContextState?.locale!]}
                                              key={i}
                                              lang={pageContextState?.locale!}
                                              template={{
                                                  "underlined-row": (props: any) => (
                                                      <div className="flex items-center justify-between border-white-smoke-light border-b py-2 text-dove-grey hover:bg-gray-300 hover:bg-opacity-40">
                                                          {props.children}
                                                      </div>
                                                  ),
                                                  numeric: (props: any) => (
                                                      <Text
                                                          type="P"
                                                          className="text-dove-grey text-right"
                                                      >
                                                          {props.children}
                                                      </Text>
                                                  ),
                                                  externalLink: (props: any) => (
                                                      <Link
                                                          to={props.url}
                                                          type="external"
                                                          className={`${renderLinkColor(
                                                              props?.colour || ""
                                                          )} underline`}
                                                      >
                                                          {props.children}
                                                      </Link>
                                                  ),
                                                  internalLink: (props: any) => (
                                                      <Link
                                                          to={getUrl(
                                                              lang!,
                                                              props?.reference?.slug?.current ||
                                                                  props?.reference?.slug?.[lang!]
                                                                      .current
                                                          )}
                                                          type="internal"
                                                          className={`${renderLinkColor(
                                                              props?.colour || ""
                                                          )} cursor-pointer`}
                                                      >
                                                          {props.children}
                                                      </Link>
                                                  ),
                                              }}
                                          />
                                      ) : (
                                          ""
                                      );
                                  })
                                : ""}
                        </div>
                    }
                </div>
            </div>
            {button?.buttonText?.[pageContextState?.locale!] ? (
                <div className="mt-8 mb-4">
                    <Link
                        to={getUrl(
                            lang,
                            getButtonLink(button?.buttonLink, lang),
                            button?.buttonLink?._type === "simpleDownloadAsset"
                        )}
                        type={
                            button?.buttonLink?._type === "simpleDownloadAsset"
                                ? "external"
                                : "internal"
                        }
                        className={`${
                            imageUrl
                                ? "text-white border-white"
                                : "text-dove-grey border-payne-grey"
                        }
        font-semibold  border-b-2  pb-1`}
                    >
                        {button?.buttonText?.[pageContextState?.locale!]}
                    </Link>
                </div>
            ) : (
                ""
            )}
        </section>
    );
};

export default InfoCard;
