import React from 'react'
import CheckIcon from '../svg/CheckIcon';

interface Props {
  className?: string;
  label?: string;
  defaultChecked?: boolean;
  name: string;
  value?: string;
  handleChange: (e: any) => void;
  inpVal?: string;
  type?: string;
}

const Checkbox: React.FC<Props> = ({ className="border-new-grey", label, name, inpVal, handleChange, children, ...props }) => {
  const radioCheck = () => inpVal === props?.value && <CheckIcon/>;
  const checkBoxCheck = () => props?.defaultChecked && <CheckIcon/>;
  const checkType = props?.type === 'checkbox' ? checkBoxCheck : radioCheck;
  return (
    <div 
      onClick={
        () => {         
        handleChange({target: {value: props.value, name}})
      }} 
      className="box-border flex items-center justify-center cursor-pointer mb-8"
    >
      <div style={{minWidth: '1.5em'}} className={`box-border border-dove-grey border-2 border-solid w-6 h-6 mr-4 rounded-sm cursor-pointer flex items-center justify-center ${className}`}>
        {checkType()}
      </div>
      <label htmlFor={name} className="box-border text-new-grey m-0 p-0 text-base relative cursor-pointer flex-wrap">{label || children}</label>
      <input 
        type="radio" 
        className="hidden" 
        name={name} 
        {...props} 
      />
    </div>
  )
}

export default Checkbox
