import React from 'react';
import Header from '../organism/Header';
import Footer from '../organism/Footer';
import SEO from '../seo';

interface IProp {
  metaDescription: string
  metaTitle: string
  meta?: [],
  lang: string
}

const Layout: React.FC<IProp> = ({ children, metaDescription = '', meta = [], metaTitle = '',  lang = 'en' }) => {
  return (
  <>
    <SEO description={metaDescription} title={metaTitle} meta={meta} lang={lang} />
    <Header />
      {children}
    <Footer />
  </>
  )
}

export default Layout;
